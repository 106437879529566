import React, { Component, useState, useRef } from "react";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import { useCookies, withCookies, Cookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { Suspense } from "react";
import { Link, useHistory } from "react-router-dom";

// import HomeNT from "./pages/HomeNewTheme";
const HomeNT = React.lazy(() => import("./pages/HomeNewTheme"));
// import AddDesignFLNT from "./pages/AddDesignFLNT";
const AddDesignFLNT = React.lazy(() => import("./pages/AddDesignFLNT"));

// import AddColor from "./pages/addColor";
const AddColor = React.lazy(() => import("./pages/addColor"));

const FabricMaster = React.lazy(() => import("./pages/fabricMaster"));

// import MyDesignsNT from "./pages/MyDesignsFLNT";
const MyDesignsNT = React.lazy(() => import("./pages/MyDesignsFLNT"));

// import ViewDesignNT from "./pages/ViewDesignNT";
const ViewDesignNT = React.lazy(() => import("./pages/ViewDesignNT"));

// const HomeNT = React.lazy(()=>import("./pages/HomeNewTheme"));

// import MyDesignsSC from "./pages/MyDesignsSC";
const MyDesignsSC = React.lazy(() => import("./pages/MyDesignsSC"));

// import ViewDesignSC from "./pages/ViewDesignSC";
const ViewDesignSC = React.lazy(() => import("./pages/ViewDesignSC"));

// import SendForCosting from "./pages/SendForCosting";
const SendForCosting = React.lazy(() => import("./pages/SendForCosting"));

// import DCuration from "./pages/3DCurationSC";
const DCuration = React.lazy(() => import("./pages/3DCurationSC"));

// import Add3D from "./pages/add3D";
const Add3D = React.lazy(() => import("./pages/add3D"));

// import MyDesigns3D from "./pages/MyDesigns3D";
const MyDesigns3D = React.lazy(() => import("./pages/MyDesigns3D"));

// import View3D from "./pages/view3D";
const View3D = React.lazy(() => import("./pages/view3D"));

// import Login from "./pages/login";
const Login = React.lazy(() => import("./pages/login"));

// import BuyerEnquiries from "./pages/buyerEnquiries";
const BuyerEnquiries = React.lazy(() => import("./pages/buyerEnquiries"));

// import AddEnquiry from "./pages/addEnquiry";
const AddEnquiry = React.lazy(() => import("./pages/addEnquiry"));

// import ViewEnquiry from "./pages/ViewEnquiry";
const ViewEnquiry = React.lazy(() => import("./pages/ViewEnquiry"));

// import Catalogue from "./pages/catalogue";
const Catalogue = React.lazy(() => import("./pages/catalogue"));

// import DesignDetails from "./pages/designDetails";
const DesignDetails = React.lazy(() => import("./pages/designDetails"));

// import SignUp from "./pages/signup";
const SignUp = React.lazy(() => import("./pages/signup"));

// import NewUsers from "./pages/newUsers";
const NewUsers = React.lazy(() => import("./pages/newUsers"));

// import ApproveUsers from "./pages/approveUsers";
const ApproveUsers = React.lazy(() => import("./pages/approveUsers"));

// import MasterTracker from "./pages/masterTracker";
const MasterTracker = React.lazy(() => import("./pages/masterTracker"));

// import MarginApprovals from "./pages/marginApprovals";
const MarginApprovals = React.lazy(() => import("./pages/marginApprovals"));

// import PassReq from "./pages/passReq";
const PassReq = React.lazy(() => import("./pages/passReq"));

// import PassReset from "./pages/PassReset";
const PassReset = React.lazy(() => import("./pages/PassReset"));

function App() {
  const [selectedDesign, setSelectedDesign] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [designID, setDesignID] = useState(0);
  const [userID, setUserID] = useState();
  const [userRole, setUserRole] = useState();
  const [loginID, setLoginID] = useState();
  const [userName, setUserName] = useState();
  const [loginStatus, setLoginStatus] = useState(-1);
  const [theme, setTheme] = useState("light");
  const [headerData, setHeaderData] = useState([]);
  const [isHeader, setIsHeader] = useState(false);
  const [preserveState, setPreserveState] = useState([]);
  const [preserveOrder, setPreserveOrder] = useState(null);
  const [preserveSearchText, setPreserveSearchText] = useState("");
  const [preserveValByOrder, setPreserveValByOrder] = useState("");
  const [preserveMyDesigns, setPreserveMyDesigns] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [admin, setAdmin] = useState(0);
  const [adminID, setAdminID] = useState();
  const [role, setRole] = useState('');
  const [showmodal, setshowmodal] = useState('false');
  const [cookies, setCookie, removeCookie] = useCookies([
    "username",
    "password",
  ]);

  if (typeof window !== "undefined") {
    window.React = React;
  }
  const history = useHistory();

  const handleRole = (id)=>{
    setUserID(id)
    history.push('/home')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Dash Board</title>
      </Helmet>
      <div></div>
      <Route exact path="/">
        <Suspense fallback={<div>Loading...</div>}>
          <Login
            setUserID={setUserID}
            setLoginStatus={setLoginStatus}
            setUserRole={setUserRole}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            setUserNameX={setUserName}
            admin={admin}
            setAdmin={setAdmin}
            theme={theme}
            setTheme={setTheme}
            headerData={headerData}
            setHeaderData={setHeaderData}
            isHeader={isHeader}
            setIsHeader={setIsHeader}
            setCookie={setCookie}
            cookies={cookies}
            setLoginID={setLoginID}
            setAdminID={setAdminID}
            setshowmodal={setshowmodal}
          />
        </Suspense>
      </Route>
      <Route exact path="/passReq">
      <Suspense fallback={<div>Loading...</div>}>
        <PassReq
          setUserID={setUserID}
          setLoginStatus={setLoginStatus}
          setUserRole={setUserRole}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setUserNameX={setUserName}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/passReset">
      <Suspense fallback={<div>Loading...</div>}>
        <PassReset
          setUserID={setUserID}
          setLoginStatus={setLoginStatus}
          setUserRole={setUserRole}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setUserNameX={setUserName}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/signup">
      <Suspense fallback={<div>Loading...</div>}>
        <SignUp
          setUserID={setUserID}
          setLoginStatus={setLoginStatus}
          setUserRole={setUserRole}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setUserNameX={setUserName}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/home">
      <Suspense fallback={<div>Loading...</div>}>
        <HomeNT
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          handleRole = {handleRole}
          setSelectedUser={setSelectedUser}
          admin={admin}
          setAdmin={setAdmin}
          setshowmodal={setshowmodal}
          showmodal={showmodal}
          setTheme={setTheme}
          userID={userID}
          adminID={adminID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        />
        </Suspense>
      </Route>
      <Route exact path="/masterTracker">
      <Suspense fallback={<div>Loading...</div>}>
        <MasterTracker
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/marginApprovals">
      <Suspense fallback={<div>Loading...</div>}>
        <MarginApprovals
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/addDesign">
      <Suspense fallback={<div>Loading...</div>}>
        <AddDesignFLNT
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/addColor">
      <Suspense fallback={<div>Loading...</div>}>
        <AddColor
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>

      <Route exact path="/fabricMaster">
      <Suspense fallback={<div>Loading...</div>}>
        <FabricMaster
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>

      <Route exact path="/designsNT">
      <Suspense fallback={<div>Loading...</div>}>
        <MyDesignsNT
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          theme={theme}
          setTheme={setTheme}
          cookies={cookies}
          removeCookie={removeCookie}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/designsSC">
      <Suspense fallback={<div>Loading...</div>}>
        <MyDesignsSC
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          theme={theme}
          setTheme={setTheme}
          preserveState={preserveState}
          setPreserveState={setPreserveState}
          preserveOrder={preserveOrder}
          setPreserveOrder={setPreserveOrder}
          preserveSearchText={preserveSearchText}
          setPreserveSearchText={setPreserveSearchText}
          preserveValByOrder={preserveValByOrder}
          setPreserveValByOrder={setPreserveValByOrder}
          preserveMyDesigns={preserveMyDesigns}
          setPreserveMyDesigns={setPreserveMyDesigns}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
        handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/viewDesignNT">
      <Suspense fallback={<div>Loading...</div>}>
        <ViewDesignNT
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/viewDesignSC">
      <Suspense fallback={<div>Loading...</div>}>
        <ViewDesignSC
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          setLoginStatus={setLoginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          preserveState={preserveState}
          setPreserveState={setPreserveState}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/sendForCosting">
      <Suspense fallback={<div>Loading...</div>}>
        <SendForCosting
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          loginID={loginID}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/3DCuration">
      <Suspense fallback={<div>Loading...</div>}>
        <DCuration
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/buyerEnquiries">
      <Suspense fallback={<div>Loading...</div>}>
        <BuyerEnquiries
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/approveUsers">
      <Suspense fallback={<div>Loading...</div>}>
        <ApproveUsers
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/newUsers">
      <Suspense fallback={<div>Loading...</div>}>
        <NewUsers
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/add3D">
      <Suspense fallback={<div>Loading...</div>}>
        <Add3D
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/designs3D">
      <Suspense fallback={<div>Loading...</div>}>
        <MyDesigns3D
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/view3D">
      <Suspense fallback={<div>Loading...</div>}>
        <View3D
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/addEnquiry">
      <Suspense fallback={<div>Loading...</div>}>
        <AddEnquiry
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          theme={theme}
          setTheme={setTheme}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/viewEnquiry">
      <Suspense fallback={<div>Loading...</div>}>
        <ViewEnquiry
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/cat">
      <Suspense fallback={<div>Loading...</div>}>
        <Catalogue
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          designID={designID}
          setDesignID={setDesignID}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          cartItems={cartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
      <Route exact path="/designDetails">
      <Suspense fallback={<div>Loading...</div>}>
        <DesignDetails
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          userID={userID}
          userRole={userRole}
          userName={userName}
          loginStatus={loginStatus}
          theme={theme}
          setTheme={setTheme}
          headerData={headerData}
          setHeaderData={setHeaderData}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          setLoginStatus={setLoginStatus}
          designID={designID}
          setDesignID={setDesignID}
          cookies={cookies}
          removeCookie={removeCookie}
          setCartItems={setCartItems}
          cartItems={cartItems}
          handleRole = {handleRole}
        admin = {admin}
        setAdmin = {setAdmin}
        showmodal = {showmodal}
        setshowmodal = {setshowmodal}
        adminID={adminID}
        />
        </Suspense>
      </Route>
    </React.Fragment>
  );
}

export default withCookies(App);
